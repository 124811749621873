import axios from "axios";
export default function axiosApi(url, method, params, headers, responseType) {
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: 'http://localhost:886' + url,
            headers: headers,
            responseType: responseType,
            data: params
        })
            .then((res) => {
            resolve(res.data);
        })
            .catch((err) => {
            reject(err);
        });
    });
}
